@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$merchandising-caroussel-width: 100% !default;
$listings-product-width: 50% !default;
$listings-product-spacing: 10px !default;
$listings-product-margin-bottom: 20px !default;

.product-list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 0 16px 16px;

	&__item {
		width: 100%;
		margin-bottom: 20px;
	}

	@include gridle_state(md) {
		padding: 0;

		&__item {
			width: calc(#{$listings-product-width} - #{$listings-product-spacing});
			margin-bottom: $listings-product-margin-bottom;
			min-height: 400px; /* todo temporaire pour ne pas casser 2x2 */
		}
	}
}
