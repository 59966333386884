@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$smartdp-search-marketing-color: $black !default;
$smartdp-search-form-background-color: $black !default;

.smartdp-search {
	position: relative;

	&__image {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 360px;

		@include gridle_state(md) {
			height: 460px;
		}
	}

	&__content {
		position: relative;
		z-index: 2;

		.smartdp-marketing-headline {
			padding: 50px 16px 24px;

			@include gridle_state(md) {
				padding: 48px 0 24px;
				max-width: 1024px;
				margin: 0 auto;
			}
		}
	}

	&__form {
		margin: 0 16px 6px;

		&--mini-version {
			margin: 0 16px 22px;
		}

		.sdp-search-form {
			padding: 16px 16px 24px;
			background: $smartdp-search-form-background-color;
			border-radius: $border-radius-big;
		}

		@include gridle_state(md) {
			max-width: 1024px;
			margin: 0 auto 24px;

			.sdp-search-form {
				margin: 0;
				position: unset;
				top: unset;
				background: unset;
				padding: 0;
				width: 1024px;
				display: flex;
				flex-direction: column;

				&__form {
					@include gridle_state(md) {
						height: 80px;
						box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
						border-radius: $border-radius-big;
						background: white;
					}
				}

				&__footer {
					padding: 16px;
					border-radius: $border-radius-big;
					height: 100px;
					box-sizing: border-box;
					margin-top: 0;
				}

				&__field {
					height: 100%;

					.advanced-select__select {
						height: 100%;

						.advanced-select__select {
							&__value-container,
							&__control {
								height: 100%;
							}
						}
					}
				}

				&__error {
					padding-top: 16px;

					.alert-message {
						margin: 0;
						border-radius: $border-radius-big;
					}
				}

				#departureCity {
					border-top-left-radius: $border-radius-big;
					border-bottom-left-radius: $border-radius-big;
				}

				.advanced-select__select__input-container,
				.advanced-select__select__single-value {
					margin-top: 20px;
				}

				.date-calendar-input__input,
				.travellers-room-button__input {
					margin-top: 35px;
				}

				.advanced-select__label,
				.date-calendar-input__label,
				.travellers-room-button__label {
					top: 31px;
				}

				.date-calendar-input--touched .date-calendar-input__label,
				.advanced-select--touched .advanced-select__label,
				.travellers-room-button--touched .travellers-room-button__label {
					top: 22px;
				}

				.advanced-select__select__clear-indicator {
					margin-top: 16px;
				}
			}
		}
	}

	.dynamic-blocks-renderer--alternate-colors {
		& > .dynamic-blocks-renderer__item:nth-child(odd) {
			background: white;
		}

		& > .dynamic-blocks-renderer__item:nth-child(even) {
			// background: $grey-light;
			background: white;
		}

		& > .dynamic-blocks-renderer__item:first-child {
			z-index: 2;
			background: linear-gradient(
				180deg,
				transparent 0,
				transparent 125px,
				#fff 126px,
				#fff 100%
			);

			@include gridle_state(md) {
				background: transparent;
				z-index: unset;
			}
		}
	}

	&__marketing {
		color: white;
		text-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);
		line-height: 1;

		@include gridle_state(md) {
			display: flex;
			width: 100%;
			justify-content: center;
		}
	}

	&__marketing-container {
		position: absolute;
		height: 130px;
		bottom: 0;
		left: 0;
		right: 0;
		background-image: linear-gradient(
			to bottom,
			rgba(51, 51, 51, 0),
			$smartdp-search-marketing-color 100%
		);
		padding-left: 10px;
		padding-right: 10px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 15px;

		@include gridle_state(md) {
			background: transparent;
			z-index: unset;
		}
	}

	em {
		font-style: italic;
	}

	&__container {
		&--custom-header {
			position: relative;

			@include gridle_state(md) {
				padding-top: 72px;
			}
		}
	}

	&__dynamic-blocks {
		margin-top: -24px;
		padding-top: 24px;
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.05) 0%,
			rgba(255, 255, 255, 1) 3%,
			rgba(255, 255, 255, 1) 100%
		);

		@include gridle_state(md) {
			margin-top: 0;
			padding-top: 0;
		}

		> .dynamic-blocks-renderer {
			@include container;

			> .dynamic-blocks-renderer__item > div {
				box-sizing: border-box;
				padding: 32px 16px;

				@include gridle_state(md) {
					padding: 40px 0;
				}
			}
		}

		.multi-blocks {
			.dynamic-blocks-renderer__item {
				&:not(:last-child) {
					& > div:not(.merch-carousel-block) {
						padding-bottom: 24px;

						@include gridle_state(md) {
							padding-bottom: 32px;
						}
					}

					& > .merch-carousel-block {
						padding-bottom: 24px;
					}
				}

				& > .pp-carousel-block {
					padding: 0;
				}
			}
		}
	}
}
